<template>
  <b-card-code :title="titleForm">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nama Aset">
              <validation-provider
                #default="{ errors }"
                name="Nama Aset"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.name"
                  placeholder="Nama Aset"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Aset Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Lokasi/Alamat">
              <validation-provider
                #default="{ errors }"
                name="Lokasi/Alamat"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.address"
                  placeholder="Lokasi/Alamat"
                />
                <small class="text-danger">{{
                  errors[0] ? "Lokasi/Alamat Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Booking">
              <validation-provider #default="{ errors }" name="Tanggal Booking">
                <flat-pickr
                  v-model="formSubmit.booking_payment_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Booking"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Booking Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Jumlah Booking">
              <validation-provider #default="{ errors }" name="Jumlah Booking">
                <b-form-input
                  v-model="formSubmit.booking_payment_amount"
                  placeholder="Jumlah Booking"
                  @input="handleInputBooking"
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah Booking Wajib Diisi" : ""
                }}</small>
                 <small v-if="formSubmit.booking_payment_amount">
                  Rp. {{ booking_payment_amount2 }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal DP">
              <validation-provider #default="{ errors }" name="Tanggal DP">
                <flat-pickr
                  v-model="formSubmit.down_payment_date"
                  class="form-control"
                  placeholder="Pilih Tanggal DP"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal DP Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Jumlah DP">
              <validation-provider #default="{ errors }" name="Jumlah DP">
                <b-form-input
                  v-model="formSubmit.down_payment_amount"
                  placeholder="Jumlah DP"
                  @input="handleInputHarga4"
                />
                <small class="text-danger">{{
                  errors[0] ? "Jumlah DP Wajib Diisi" : ""
                }}</small>

                <small v-if="formSubmit.down_payment_amount">
                  Rp. {{ price4 }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Harga">
              <validation-provider
                #default="{ errors }"
                name="Harga"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.price"
                  placeholder="Harga"
                  @input="handleInputHarga"
                />
                <small class="text-danger">{{
                  errors[0] ? "Harga Wajib Diisi" : ""
                }}</small>

                <small v-if="formSubmit.price"> Rp. {{ price2 }} </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Tanggal Jatuh Tempo">
              <validation-provider
                #default="{ errors }"
                name="Tanggal Jatuh Tempo"
                rules="required"
              >
                <flat-pickr
                  v-model="formSubmit.due_date"
                  class="form-control"
                  placeholder="Pilih Tanggal Jatuh Tempo"
                  :config="configs.allowInput"
                />
                <small class="text-danger">{{
                  errors[0] ? "Tanggal Jatuh Tempo Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Telepon Kontak">
              <validation-provider
                #default="{ errors }"
                name="Telepon Kontak"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.contact_phone"
                  placeholder="Telepon Kontak"
                />
                <small class="text-danger">{{
                  errors[0] ? "Telepon Kontak Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Nama Kontak">
              <validation-provider
                #default="{ errors }"
                name="Nama Kontak"
                rules="required"
              >
                <b-form-input
                  v-model="formSubmit.contact_name"
                  placeholder="Nama Kontak"
                />
                <small class="text-danger">{{
                  errors[0] ? "Nama Kontak Wajib Diisi" : ""
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Catatan">
              <b-form-textarea
                rows="3"
                v-model="formSubmit.note"
                placeholder="Masukkan Catatan"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" class="mt-2" v-if="this.lastPathParams != `form`">
            <b-form-checkbox v-model="formSubmit.is_cancel">
              Batal
            </b-form-checkbox>
          </b-col>
        </b-row>

        <!-- table dokumen Aset hanya saat edit data saja -->
        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> Daftar Dokumen Aset </b></b-col
          >
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columnsDocumentAset"
              :rows="rowsDocumentAset"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'document_type'">
                  {{ props.row.document_type.replaceAll("_", " ") }}
                </span>

                <span v-if="props.column.field === 'upload_date'">
                  {{
                    props.row.upload_date === null
                      ? "-"
                      : props.row.upload_date.substring(0, 10)
                  }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="info"
                    v-b-tooltip.hover.v-info
                    title="Upload Dokumen"
                    class="btn-icon mr-2"
                    @click="uploadDokumen(props)"
                  >
                    <feather-icon icon="UploadIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-if="props.row.document_url == null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Download Dokumen"
                    class="btn-icon"
                    @click="downloadNull(props)"
                  >
                    <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-if="props.row.document_url != null"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-b-tooltip.hover.v-primary
                    title="Download Dokumen"
                    class="btn-icon"
                    @click="downloadDokumen(props)"
                  >
                    <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <!-- end table dokumen Aset -->

        <!-- table History Pembayaran Aset hanya saat edit data saja -->
        <b-row v-if="this.lastPathParams != `form`">
          <b-col cols="3" align="start" class="mt-2">
            <b> History Pembayaran Aset </b></b-col
          >

          <b-col cols="6" align="start" class="mt-2"></b-col>

          <b-col cols="3" align="end" class="mt-2">
            <b-button variant="success" @click="showModalTambahPembayaran()">
              Tambah Pembayaran
            </b-button>
          </b-col>
        </b-row>

        <b-row class="mt-1" v-if="this.lastPathParams != `form`">
          <b-col md="12">
            <vue-good-table
              mode="remote"
              :columns="columnspayment_history"
              :rows="rowspayment_history"
              :rtl="direction"
              :search-options="{
                enabled: false,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: false,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'payment_name'">
                  {{ props.row.payment_name }}
                </span>

                <span v-if="props.column.field === 'note'">
                  {{ props.row.note }}
                </span>

                <span v-if="props.column.field === 'payment_date'">
                  {{ props.row.payment_date.substring(0, 10) }}
                </span>

                <span v-if="props.column.field === 'payer_name'">
                  {{ props.row.payer_name }}
                </span>

                <span v-if="props.column.field === 'payment_amount'">
                  Rp.
                  {{
                    props.row.payment_amount
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")
                  }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span
                    v-if="
                      props.row.payment_name === `DP Cash` ||
                      props.row.payment_name === `Booking`
                    "
                  >
                    -
                  </span>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning"
                    v-b-tooltip.hover.v-warning
                    title="Ubah Pembayaran"
                    class="btn-icon mr-2"
                    @click="showModalUbah(props)"
                    v-if="
                      props.row.payment_name != `DP Cash` &&
                      props.row.payment_name != `Booking`
                    "
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="danger"
                    v-b-tooltip.hover.v-danger
                    title="Hapus Pembayaran"
                    class="btn-icon"
                    @click="deleteInfo(props)"
                    v-if="
                      props.row.payment_name != `DP Cash` &&
                      props.row.payment_name != `Booking`
                    "
                  >
                    <feather-icon icon="Trash2Icon" class="cursor-pointer" />
                  </b-button>
                </span>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
        <!-- end table History Pembayaran Aset -->

        <!-- button -->

        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitValidate"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- modal form pembayaran aset -->
    <b-modal
      id="modal-form-pembayaran-aset"
      ref="ref-form-pembayaran-aset"
      :title="FormPembayaranAset"
      hide-footer="true"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRulesPembayaran">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label-for="price">
                <template v-slot:label> Nama Aset </template>
                <b-form-input id="price" v-model="formSubmit.name" disabled />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tanggal Pembayaran <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Pembayaran"
                  rules="required"
                >
                  <flat-pickr
                    v-model="formPaymentHistory.payment_date"
                    class="form-control"
                    placeholder="Pilih Tanggal Pembayaran"
                    :config="configs.allowInput"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Tanggal Pembayaran Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tipe Pembayaran <span class="text-danger">*</span>
                </template>

                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <v-select
                    v-model="formPaymentHistory.payment_type"
                    :options="paymentTypeOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    placeholder="Pilih Tipe Pembayaran"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Tipe Pembayaran Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              v-if="
                this.formPaymentHistory.payment_type === 'OPERASIONAL' ||
                this.formPaymentHistory.payment_type === 'DP'
              "
            >
              <b-form-group label-for="name">
                <template v-slot:label>
                  Nama Operasional <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Nama Operasional"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formPaymentHistory.payment_name"
                    placeholder="Masukkan Nama Operasional"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Nama Operasional Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Jumlah Bayar <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Jumlah Bayar"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formPaymentHistory.payment_amount"
                    placeholder="Masukkan Jumlah Bayar"
                    @input="handleInputHarga3"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Jumlah Bayar Wajib Diisi" : ""
                  }}</small>

                  <small v-if="formPaymentHistory.payment_amount">
                    Rp. {{ price3 }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label-for="name">
                <template v-slot:label>
                  Dibayar Oleh <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Dibayar Oleh"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="formPaymentHistory.payer_name"
                    placeholder="Masukkan Dibayar Oleh"
                  />
                  <small class="text-danger">{{
                    errors[0] ? "Dibayar Oleh Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Catatan" label-for="mc-country">
                <b-form-textarea
                  rows="3"
                  v-model="formPaymentHistory.note"
                  placeholder="Masukkan Catatan"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-row class="mt-1 mb-2">
          <b-col align="end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="kembaliModalForm()"
              class="mr-2"
            >
              Batal
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitFormPembayaran()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <!-- end modal form pembayaran aset -->

    <!-- modal form dokumen aset -->
    <b-modal
      id="modal-form-dokumen-aset"
      ref="ref-form-upload-dokumen"
      title="Form Upload Dokumen"
      hide-footer="true"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRulesDokumen">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group label-for="price">
                <template v-slot:label> File Dokumen </template>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-file
                    v-model="formDokumen"
                    ref="fileupload"
                    placeholder="Pilih file anda"
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                    @change="handleFileUpload($event)"
                  ></b-form-file>
                  <small class="text-danger">{{
                    errors[0] ? "File Dokumen Wajib Diisi" : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>

        <b-row class="mt-1 mb-2">
          <b-col align="end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="kembaliModalFormDokumen()"
              class="mr-2"
            >
              Batal
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitFileDokumen()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
    <!-- end modal form dokumen aset -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      emailValue: "",
      name: "",
      required,
      email,

      FormPembayaranAset: "",

      statusOptions: [
        {
          _id: "KAWIN",
          name: "KAWIN",
        },
        {
          _id: "BELUM_KAWIN",
          name: "BELUM KAWIN",
        },
        {
          _id: "JANDA",
          name: "JANDA",
        },
        {
          _id: "DUDA",
          name: "DUDA",
        },
      ],

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      titleForm: "",
      price2: "",
      price4: "",
      booking_payment_amount2: "",

      lastPathParams: "",

      formSubmit: {
        name: "",
        address: "",
        price: "",
        price3: "",
        due_date: "",
        booking_payment_date: "",
        booking_payment_amount: "",
        down_payment_date: "",
        down_payment_amount: "",
        contact_phone: "",
        contact_name: "",
        note: "",
        is_cancel: false,
      },

      formDetail: {
        kavling_name: "",
        block_name: "",
      },

      id_asset: "",
      block_name: "",
      kavling_name: "",

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],
      searchTerm: "",

      total_paid: "",
      total_unpaid: "",

      formDokumen10mb: false,

      rowsDocumentAset: [],
      columnsDocumentAset: [
        {
          label: "Nama Dokumen",
          field: "document_type",
          thClass: "text-center",
        },
        {
          label: "Tanggal Upload",
          field: "upload_date",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],

      rowspayment_history: [],
      columnspayment_history: [
        {
          label: "Nama Pembayaran",
          field: "payment_name",
          thClass: "text-center",
        },
        {
          label: "Tanggal Bayar",
          field: "payment_date",
          thClass: "text-center",
        },
        {
          label: "Jumlah Bayar",
          field: "payment_amount",
          thClass: "text-center",
        },
        {
          label: "Dibayar Oleh",
          field: "payer_name",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],

      formDisabledPayment: {},

      formPaymentHistory: {
        payment_date: "",
        payment_type: "",
        payment_name: "",
        payment_amount: "",
        payer_name: "",
        note: "",
      },

      formDokumen: null,
      id_dokumen: "",

      aksiPembayaranAset: "",
      idPembayaranAset: "",

      paymentTypeOptions: [],

      // end
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    this.getPaymentType();

    if (this.lastPathParams != "form") {
      this.getIdData();
      this.titleForm = "Form Update Data Aset";
    } else {
      this.titleForm = "Form Tambah Aset Baru";
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    submitValidate() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.lastPathParams === "form") {
            return new Promise(() => {
              axios
                .post("v1/asset", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Aset Berhasil`,
                    },
                  });
                  this.$router.push("/aset");
                  this.$refs["ref-form-data-aset"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/asset/" + this.$route.params.id, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Aset Berhasil`,
                    },
                  });
                  this.$router.push("/aset");
                  this.$refs["ref-form-data-aset"].hide();
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            });
          }
        }
      });
    },

    getIdData() {
      return new Promise(() => {
        axios
          .get("v1/asset/" + this.$route.params.id)
          .then((res) => {
            this.id_asset = res.data._id;

            this.formSubmit.is_cancel = res.data.is_cancel;
            this.formSubmit.name = res.data.name;
            this.formSubmit.address = res.data.address;
            this.formSubmit.price = res.data.price;
            this.formSubmit.down_payment_date = res.data.down_payment_date;
            this.formSubmit.down_payment_amount = res.data.down_payment_amount;
            this.formSubmit.contact_phone = res.data.contact_phone;
            this.formSubmit.contact_name = res.data.contact_name;
            this.formSubmit.note = res.data.note;
            this.formSubmit.due_date = res.data.due_date;
            this.formSubmit.booking_payment_date =
              res.data.booking_payment_date;
            this.formSubmit.booking_payment_amount =
              res.data.booking_payment_amount;

            this.rowsDocumentAset = res.data.documents;
            this.rowspayment_history = res.data.payment_history;

            this.handleInputHarga();
            this.handleInputBooking();
            this.handleInputHarga4();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    showModalTambahPembayaran() {
      this.payment_date = "";
      this.payment_type = "";
      this.payment_name = "";
      this.payment_amount = "";
      this.payer_name = "";
      this.note = "";
      this.price3 = "";

      this.formPaymentHistory.payment_date = "";
      this.formPaymentHistory.payment_type = "";
      this.formPaymentHistory.payment_name = "";
      this.formPaymentHistory.payment_amount = "";
      this.formPaymentHistory.payer_name = "";
      this.formPaymentHistory.note = "";

      this.FormPembayaranAset = "Form Pembayaran Aset";

      this.$refs["ref-form-pembayaran-aset"].show();
      this.aksiPembayaranAset = "tambah";
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get("v1/asset/" + this.id_asset + "/" + props.row.id)
          .then((res) => {
            this.idPembayaranAset = res.data.id;

            this.formPaymentHistory.payment_date = res.data.payment_date.substr(
              0,
              10
            );
            this.formPaymentHistory.payment_type = res.data.payment_type;
            this.formPaymentHistory.payment_name = res.data.payment_name;
            this.formPaymentHistory.payment_amount = res.data.payment_amount;
            this.formPaymentHistory.payer_name = res.data.payer_name;
            this.formPaymentHistory.note = res.data.note;

            this.FormPembayaranAset = "Form Update Pembayaran Aset";

            this.handleInputHarga3();

            this.aksiPembayaranAset = "ubah";
            this.$refs["ref-form-pembayaran-aset"].show();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    submitFormPembayaran() {
      this.$refs.simpleRulesPembayaran.validate().then((success) => {
        if (success) {
          if (this.aksiPembayaranAset === "tambah") {
            return new Promise(() => {
              axios
                .post(
                  "v1/asset/" + this.id_asset + "/payment",
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Pembayaran Aset Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-pembayaran-aset"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put(
                  "v1/asset/" + this.id_asset + "/" + this.idPembayaranAset,
                  this.formPaymentHistory
                )
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Pembayaran Aset Berhasil`,
                    },
                  });
                  this.getIdData();
                  this.$refs["ref-form-pembayaran-aset"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          }
        }
      });
    },

    kembaliModalForm() {
      this.$refs["ref-form-pembayaran-aset"].hide();
    },

    uploadDokumen(props) {
      this.id_dokumen = props.row.id;
      this.$refs["ref-form-upload-dokumen"].show();
    },

    handleFileUpload(event) {
      if (event.target.files[0].size > 10 * 1024 * 1024) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ukuran file tidak boleh lebih dari 10 mb",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });

        this.formDokumen10mb = true;
        return;
      } else {
        this.formDokumen10mb = false;
        this.formDokumen = event.target.files;
      }
    },

    submitFileDokumen() {
      if (this.formDokumen10mb === true) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Ukuran file tidak boleh lebih dari 10 mb",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else if (this.formDokumen === null) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "File Wajib Diisi",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        let formData = new FormData();
        formData.append("uploaded_document", this.formDokumen);

        return new Promise(() => {
          axios
            .post(
              "v1/asset/" +
                this.id_asset +
                "/upload_document?document_id=" +
                this.id_dokumen,
              formData
            )
            .then((response) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Upload Dokumen Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Upload Dokumen Aset Berhasil`,
                },
              });

              this.getIdData();
              this.$refs["ref-form-upload-dokumen"].hide();
            });
        });
      }
    },

    kembaliModalFormDokumen() {
      this.$refs["ref-form-upload-dokumen"].hide();
    },

    downloadDokumen(props) {
      window.open(
        "https://api-bsgproperti.mrizkyff.com/v1/asset/" +
          this.id_asset +
          "/download_document/" +
          props.row.id
      );
    },

    downloadNull(props) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title:
            "File Dokumen " +
            props.row.document_type.replaceAll("_", " ") +
            " Tidak Tersedia",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang sudah terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/asset/" + this.id_asset + "/" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Pembayaran Aset Berhasil`,
              },
            });
            this.getIdData();
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    format2: (value) =>
      (value + "").replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, "."),

    handleInputHarga() {
      this.price2 = this.format2(this.formSubmit.price);
      this.$emit("input", (this.price2 + "").replace(/[^0-9]/g, ""));
    },

    handleInputHarga3() {
      this.price3 = this.format2(this.formPaymentHistory.payment_amount);
      this.$emit("input", (this.price3 + "").replace(/[^0-9]/g, ""));
    },

    handleInputHarga4() {
      this.price4 = this.format2(this.formSubmit.down_payment_amount);
      this.$emit("input", (this.price4 + "").replace(/[^0-9]/g, ""));
    },
      handleInputBooking() {
      this.booking_payment_amount2 = this.format2(this.formSubmit.booking_payment_amount);
      this.$emit("input", (this.booking_payment_amount2 + "").replace(/[^0-9]/g, ""));
    },

    getPaymentType() {
      return new Promise(() => {
        axios.get("v1/asset/combo_payment_type").then((res) => {
          this.paymentTypeOptions = res.data;
        });
      });
    },

    backButton() {
      this.$router.push("/aset");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
